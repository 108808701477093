import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./views/index";
import Serviceslist from "./views/serviceslist";
import Calender from './views/Calender';
import Cart from './views/Cart';
import Carbrands from './views/carbrands';
import CarbrandDetails from './views/carbranddetails';
import Bookingslist from './views/bookingslist';

function App({...props}) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home  {...props}/>} />
        <Route path='/services/:id' exact element={<Serviceslist {...props} />} />
        <Route path='/calender' exact element={ <Calender {...props} /> } />
        <Route path='/cart' exact element={ <Cart {...props} /> } />
        {/* Carbrands */}
        <Route path='/carbrands' exact element={ <CarbrandDetails {...props} /> } />
        <Route path='/carbrand/:id' exact element={<CarbrandDetails {...props} />} />
        <Route path="/bookings" exact element={<Bookingslist {...props} /> } />
        <Route path="/bookings/:id" exact element={<Bookingslist {...props} /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
