import {APIURL} from './constants';
import axios from "axios";

const AxiosCall = {
    getCall: function(){
    },
    getBannerImages: function(){
        return axios({
         url: APIURL+"getAllBanners",
         method: "GET",
         headers: {
             'Content-Type': 'application/json'
         }
         });
    },
    getServiceslist: function(category){
        var selectedcarBrandModelId = localStorage.getItem('selectedcarBrandModelId');
        return axios({
         url: selectedcarBrandModelId != null ? APIURL+"serviceslist/"+category+"?model="+selectedcarBrandModelId : APIURL+"serviceslist/"+category,
         method: "GET",
         headers: {
             'Content-Type': 'application/json',
         }
         });
    },
    getUserBookinglist: function(category){
        return axios({
         url: APIURL+"bookings"+(category !== undefined ? '/'+category+'-'+localStorage.getItem('mobilenumber') : '/all-'+localStorage.getItem('mobilenumber')),
         method: "GET",
         headers: {
             'Content-Type': 'application/json',
         }
         });
    },
    getLoadSingleCarBrandDetailsList: (brandid) => {
       if(brandid != undefined){
        return axios({
            url:APIURL+'getcarnames?brand='+brandid+'&query=',
            method:'GET',
            headers:{}
        })
       } else {
        return axios({
            url:APIURL+'getcarnames?brand=&query=',
            method:'GET',
            headers:{}
        })
       }
       
    },
    getLoadSingleCarNamesBrandDetailsList: (brandid,carnames) => {
        return axios({
            url:APIURL+'getcarnames?brand='+brandid+'&query='+carnames,
            method:'GET',
            headers:{}
        })
    },
    getLoadCarCategoriesDetailsList: (brandid) => {
        return axios({
            url:APIURL+'carcategorieslist',
            method:'GET',
            headers:{}
        })
    },
    getServiceslistByIds: function(localstoreserviceids){
        let formData = JSON.stringify({"elements": localstoreserviceids});
        return axios({
         url: APIURL+"getServicesData",
         method: "POST",
         headers: {
            'Content-Type': 'application/json'
        },
        data: formData,
         });
    },
    getLoadTimeslots: function(selectedateurl){
        return axios({
         url: APIURL+"gettimelsots?date="+selectedateurl,
         method: "GET",
         headers: {
             'Content-Type': 'application/json',
         }
         });
    },
    getLoadCarBrandList: function(){
        return axios({
            url:APIURL+'carbrandlist',
            method:'GET',
            headers:{
                'Content-Type':'application/json'
            }
        });
    },
    cartfinishing: function(totalprice){
        var localstoreserviceids = [];
        if(localStorage.getItem('localstoreserviceids') !== null){
            localstoreserviceids = JSON.parse(localStorage.getItem('localstoreserviceids'));
        }
        localstoreserviceids = [ ...new Set(localstoreserviceids) ];
        localstoreserviceids = localstoreserviceids.filter(item => item);
        let formData = JSON.stringify({
                "elements": localstoreserviceids,
                "totalprice":totalprice,
                "gst":"",
                "selectedcar":localStorage.getItem('selectedcarId'),
                "selecteddate":localStorage.getItem('selecteddate'),
                "selectedtimeslotid":JSON.parse(localStorage.getItem('selectedtimeslotid'))[0],
                "usermobile":localStorage.getItem('mobilenumber'),
                "selectedcarBrandId":localStorage.getItem('selectedcarBrandId') ? localStorage.getItem('selectedcarBrandId') : "",
                "selectedcarBrandModelId":localStorage.getItem('selectedcarBrandModelId') ? localStorage.getItem('selectedcarBrandModelId') : "",
                "geolocationlat":localStorage.getItem('addresslatitude') ? localStorage.getItem('addresslatitude') : "",
                "geolocationlng":localStorage.getItem('addresslongitude') ? localStorage.getItem('addresslongitude') : "",
                "address":localStorage.getItem('addressname') ? localStorage.getItem('addressname') : ""
            });
        return axios({
         url: APIURL+"bookingcreate",
         method: "POST",
         headers: {
            'Content-Type': 'application/json'
        },
        data: formData,
         });
    }
}

export default AxiosCall;