import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../assets/css/main.css?v=1.0.0';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/animate.min.css?v=1.0.0';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../assets/css/font-awesome.min.css';
import '../assets/css/remixicon.min.css';
import AxiosCall from "../utils/axiosCall";
import Footer from "./footerpage";
import HomeHeader from "./homeheader";
import { useParams } from "react-router-dom";
import "../assets/css/modelpopup.css?v=0.0.1";
import { APIURL } from "../utils/constants";
import { event } from "jquery";

function withParams(Component){
    return props => <Component {...props} params={useParams() } />
}

class CarbrandDetails extends React.Component {

    constructor(props){
        super(props);
        
        this.state = {
            show:false,
            carBrandList:[],
            imagesdata:[],
            carbrandid:this.props.params.id,
            selectedBrandName:'',
            selectedBrandId:'',
            modelname:''
        }
        this.loadBannerImages = this.loadBannerImages.bind(this);
        this.showvehicleClick = this.showvehicleClick.bind(this);
        this.loadcarBrandList = this.loadcarBrandList.bind(this);
        this.updateVehicleInformation = this.updateVehicleInformation.bind(this);
        this.getcarBrandSelectedId = this.getcarBrandSelectedId.bind(this);
    }
    showvehicleClick(){
        this.setState({show:true});
    }

    updateVehicleInformation(){
        
    }

    getcarBrandSelectedId(event,id,brandname,brand, carmodel){
        this.setState({selectedBrandId:id});
        this.setState({selectedBrandName:brandname});
        this.setState({selectedmodelname:brandname});
        localStorage.setItem('selectedcarId',id);
        localStorage.setItem('selectedcarBrandId',brand);
        localStorage.setItem('selectedcarBrandModelName',brandname);
        localStorage.setItem('selectedcarBrandModelId',carmodel);
        if(localStorage.getItem('pageon') != null){
            window.location = "/"+localStorage.getItem('pageon');
        } else {
            window.location = "/";
        }
    }

    async searchCars(carbrandid, event){
        
         if(event.target.value.length > 2){
            carbrandid = carbrandid != undefined ? carbrandid : '';
            event.target.value = event.target.value != undefined ? event.target.value : '';
            let carBrandListArray = await AxiosCall.getLoadSingleCarNamesBrandDetailsList(carbrandid, event.target.value);
            this.setState({carBrandList:carBrandListArray.data});
         } else {
            this.props.params.id = this.props.params.id != undefined ? this.props.params.id : '';
            let carBrandListArray = await AxiosCall.getLoadSingleCarBrandDetailsList(this.props.params.id);
            this.setState({carBrandList:carBrandListArray.data});
         }
    }

    componentDidMount() {
        this.loadcarBrandList();
     }

     async loadcarBrandList(){
        
        let carBrandListArray = await AxiosCall.getLoadSingleCarBrandDetailsList(this.props.params.id);
        this.setState({carBrandList:carBrandListArray.data});
     }
 
     async loadBannerImages()  {
         const bannerImages = await AxiosCall.getBannerImages();
         this.setState({imagesdata:bannerImages.data})
     }

    render() {
        return (
            <> 
                <HomeHeader/>
                <header className="header">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                    <div className="locationbox">
                        <div className="mapicon"><i className="ri-map-pin-line"></i></div>
                        <div className="addressinfo">Add Address</div>
                        <div className="addressarrow"><i className="ri-arrow-down-s-line"></i></div>
                    </div>
                    </div>
                    <div className="col-md-8">
                    <div className="addvcoin">
                    <a href="#" onClick={this.showvehicleClick} className="addvechicle"> <img src="../assets/images/caricon.png" alt="" className="caricon" /> Add New Vehicle  <i className="ri-add-line"></i></a>
                    </div>
                    </div>
                </div>
                </div>
                </header>
                
                <div className="heroslider">
                <div className="container">
                <div className="row">
                <div className="brandsearch">
                <span>
                <input type="text" className="form-control" id="searchcarnames" onKeyUp={ (event) => this.searchCars(this.state.carbrandid,event)} placeholder="Search with car Names" name="mobilenumber" />
                </span>

                </div>
                <div className="brandslist">
                    <div className="row">
                        {
                            this.state.carBrandList.map( (carBrand,index) => {
                                return (
                                 <>
                                  <div className="col-md-3">
                                    <div className="brandbox">
                                        <div className="brandlogo"> <img src={ APIURL+'getbrandimage/'+carBrand.brand } /> </div>
                                        <div className="brandname"> <button type="button" className="removeborderbtn" onClick={(event) => this.getcarBrandSelectedId(event,carBrand.id,carBrand.brandname,carBrand.brand,carBrand.carmodel) } data-name={carBrand.brandname} id={carBrand.id} value={carBrand.brandname}>{carBrand.carname}</button> </div>
                                    </div>
                                    </div>
                                    </>  
                                )
                            })
                        }
                    </div>
                    </div>
                    </div>
                    </div>

                {/* <ul class="list-group">
                {
                        this.state.carBrandList.map( (carBrand,index) => {
                            return (
                                <>
                                    <li class="list-group-item" style={{padding:'20px'}}>
                                        <label><input type="radio" name="carBrand" onClick={this.getcarBrandSelectedId} data-name={carBrand.branddetailsname} id={carBrand.id} value={carBrand.branddetailsname} /></label>
                                        <span style={{marginLeft: '10px'}}>{carBrand.branddetailsname}</span></li>
                                </>
                            );
                        })
                    }
                    </ul> */}
                </div>
                
                
                <Footer/>
        </>
        );
    }
  };
  
  export default withParams(CarbrandDetails);