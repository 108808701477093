import React, { useState, useEffect } from "react";
class HomeHeader extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            cartcount: 0
        }
    }
    componentDidMount(){
        var localstoreserviceids = [];
        if(localStorage.getItem('localstoreserviceids') !== null){
            localstoreserviceids = JSON.parse(localStorage.getItem('localstoreserviceids'));
        }
        localstoreserviceids = [... new Set(localstoreserviceids)];
        localstoreserviceids = localstoreserviceids.filter(item => item);
        this.setState({cartcount:localstoreserviceids.length});
    }
    render(){
        return(
            <>
            <div className="locationrow">
                <div className="container">
                    <div className="logorow">
                    <div className="logocol"> <a href="/"> <img src="../assets/images/logo.png" alt="" className="logo" /></a></div>
                    <div className="hedright">
                    <a href="#" className="icons"> <img src="../assets/images/coinsimg.png" alt="" className="coinicon" /> Coins</a>
                    <a href={ this.state.cartcount > 0 ? "/cart" : "#" } className="carticon"> <img src="../assets/images/cart.png" alt="" /> <span> {this.state.cartcount} </span></a>
                </div>
                </div>
                </div>
                </div>
            </>
        );
    }
}

export default HomeHeader;