import React, { useState, useEffect } from "react";

class Footer extends React.Component {
    render(){
        return(
            <>
            <footer className="footer">
            <div className="container">
                <div className="footermenu">
                <ul>
                    <li className="active"> <a href="#"> <span className="homeicon"></span><strong>Home</strong>  </a> </li>
                    <li> <a href="/bookings/slotbooking"> <span className="bookings"></span> <strong>Bookings</strong> </a> </li>
                    <li> <a href="#"> <span className="hydraplus"></span><strong>Hydra Plus</strong> </a> </li>
                    <li> <a href="#"> <span className="profile"></span> <strong>Profile</strong>  </a> </li>
                </ul>
                </div>
            </div>
            </footer>
            </>
        )
    }
}

export default Footer;