import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../assets/css/main.css?v=1.0.0';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/animate.min.css?v=1.0.0';
import '../assets/css/font-awesome.min.css';
import '../assets/css/remixicon.min.css';
import AxiosCall from "../utils/axiosCall";
import Footer from "./footerpage";
import "../assets/css/main.css?v=0.0.2";
import "../assets/css/modelpopup.css?v=0.0.1";
import HomeHeader from "./homeheader";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker from "react-datepicker";
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { event } from "jquery";
import { th } from "date-fns/locale";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Calender extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            timeslotList : [],
            selecteddate:new Date(),
            selectedtimeslotid:[],
            selectedtimeslotname:'',
            selectedateurl:''
        };
        this.loadTimeslotList = this.loadTimeslotList.bind(this);
        this.submitChecktoCart = this.submitChecktoCart.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.selecttheTimeslot = this.selecttheTimeslot.bind(this);
        this.datetoString = this.datetoString.bind(this);
      }
  
      componentDidMount(){
        this.datetoString(new Date());
      }
      
      async loadTimeslotList(selectedateurl)  {
        const timeslotListData = await AxiosCall.getLoadTimeslots(selectedateurl);
        this.setState({timeslotList:timeslotListData.data})
      }

      async selecttheTimeslot(slotid,event){
        localStorage.setItem("selectedTimeslot",slotid.slottime);
        this.setState({selectedtimeslotid:[slotid.id],selectedtimeslotname : slotid.slottime});
      }

      submitChecktoCart(){
        if(this.state.selectedtimeslotid.length === 0){
          withReactContent(Swal).fire({html: <i>Please Select the timeslot</i>});
        } else {
          const selectedate = this.state.selecteddate;
          const yyyy = selectedate.getFullYear();
          let mm = selectedate.getMonth() + 1; // Months start at 0!
          let dd = selectedate.getDate();
          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
          const formattedToday = dd + '/' + mm + '/' + yyyy;
          localStorage.setItem('selectedtimeslotid',JSON.stringify(this.state.selectedtimeslotid));
          localStorage.setItem('selecteddate',formattedToday);
          window.location = "/cart";
        }
        
        return false;
      }

      datetoString(selectedate){
        const yyyy = selectedate.getFullYear();
        let mm = selectedate.getMonth() + 1; // Months start at 0!
        let dd = selectedate.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = dd + '/' + mm + '/' + yyyy;
        this.setState({selectedateurl:formattedToday});
        this.loadTimeslotList(formattedToday);
      }

      handleSelect(event){
        this.datetoString(event);
        this.setState({selecteddate:event});
      }

    render() {
        return (
            <> 
        <HomeHeader/>
        <div className="col-12" style={{display: 'flex'}}>
        <div className="col-6">
        <center><Calendar
        date={ this.state.selecteddate }
        onChange={this.handleSelect}
      /></center>
        </div>
        <div className="col-6">
        <div className="select-time">
          <h4>Select Time: {this.state.selectedtimeslotname} </h4>
          <ul className="timeslot">
            {
              this.state.timeslotList.map( (timeslot) => {
                return (
                  <>
                  {/* //disable */}
                  <li className={this.state.selectedtimeslotid.includes(timeslot.id) ? 'selected' : '' }><a href="#" onClick={ (event) => { this.selecttheTimeslot(timeslot,event); }}><span> { timeslot.slottime } </span></a></li>
                  </>
                )
              })
            }
          </ul>

    </div>
        </div>
        </div>
        <div className="col-12">
        <div className="paybar">
          <a href="#" onClick={this.submitChecktoCart}> Continue </a>
        </div>
        </div>
        <Footer/>
        </>
        );
    }
  };
  
  export default withParams(Calender);