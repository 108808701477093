import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../assets/css/main.css?v=1.0.0';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/animate.min.css?v=1.0.0';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { GoogleMap, StandaloneSearchBox, Marker } from "@react-google-maps/api";
import '../assets/css/font-awesome.min.css';
import '../assets/css/remixicon.min.css';
import AxiosCall from "../utils/axiosCall";
import { APIURL } from '../utils/constants';
import Footer from "./footerpage";
import HomeHeader from "./homeheader";
import { Link } from "react-router-dom";
import "../assets/css/modelpopup.css?v=0.0.1";
import AddressBar from "./addressbar";
import SweetAlert from 'sweetalert-react';

class Home extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            show:false,
            carBrandList:[],
            imagesdata:[],
            carCategories:[]
        }
        this.loadBannerImages = this.loadBannerImages.bind(this);
        this.showvehicleClick = this.showvehicleClick.bind(this);
        this.loadcarBrandList = this.loadcarBrandList.bind(this);
        this.loadcarCategoriesList = this.loadcarCategoriesList.bind(this);
    }
    showvehicleClick(){
        this.setState({show:true});
        this.loadcarBrandList();
    }

    componentDidMount() {
        this.loadBannerImages();
        this.loadcarCategoriesList();
     }

     async loadcarBrandList(){
        let carBrandListArray = await AxiosCall.getLoadCarBrandList();
        this.setState({carBrandList:carBrandListArray.data});
     }

     async loadcarCategoriesList(){
        let carCategoriesListArray = await AxiosCall.getLoadCarCategoriesDetailsList();
        this.setState({carCategories:carCategoriesListArray.data});
     }
 
     async loadBannerImages()  {
         const bannerImages = await AxiosCall.getBannerImages();
         this.setState({imagesdata:bannerImages.data})
     }

    render() {
        return (
            <> 
                <HomeHeader/>
                <AddressBar/>
                
                <div className="heroslider">
                <div className="container">
                <OwlCarousel className='owl-theme' items={1} loop margin={1} nav={false} navContainer={false} dotData = {false}>
                    {
                        this.state.imagesdata.map( (bannerImage,index) => {
                            return (
                                <>
                                    <div key={index+1} className="item"><img src={APIURL+"images/"+bannerImage} alt="" /></div>
                                </>
                            )
                        })
                    }
                </OwlCarousel>
                </div>
                </div>
                                        
                <div className="ourservices">
                <div className="container">
                    <div className="row">
                    {
                        this.state.carCategories.map( (cat,cindex) => {
                            return (
                                <>
                                
                                <div key={cindex} className="col-md-4">
                                <Link to={{ pathname:"/services/"+cat.id , state : { category : "car-washing"}}} ><div className="servimg"><img src={APIURL+"catimage/"+cat.id} alt="" className="serviceicons car1" /></div>
                                <h5>{cat.categoryname}</h5></Link>
                                </div>
                                </>
                            )
                        })
                    }
                    </div>
                </div>
                </div>
    
                <div className="subscription">
                <div className="container"><h3> <span>• Subscription •</span> </h3>
                <div className="row">
                <div className="col-md-6">
                <h6> <span> HYDRA PLUS</span></h6>
                    <h5>Try our <strong> Hydra Plus </strong> Membership for more benefits</h5>
                    <h4> <img src="../assets/images/star.png" alt="" /> Express Service</h4>
                    <h4><img src="../assets/images/star.png" alt="" /> 10% off on all services</h4>
                </div>
                <div className="col-md-6">
                    <img src="../assets/images/subscription.png" alt="" className="car4" />
                </div>  
                </div>
                </div>
                </div>
                <div className="container">
                <div className="referfriend">
    
                <div className="row">
                <div className="col-md-7">
                    <img src="../assets/images/refer.png" alt="" />
                </div>
                <div className="col-md-5">
                    <p>Experience the art of car care.</p>
                    <h5>Get 100 Bonus Points  <br /> by referring to your friend.</h5>
                    <div className="text-center">
                    <a href="#" className="cta">Refer a Friend</a></div>
                </div>
                </div>
                <div className="coinsimg"><img src="../assets/images/coins.png" alt="" /></div>
                </div>
                </div>
    
                <div className="container">
                <div className="videocol">
                    {/* <div className="vplay"> */}
                    {/* <div>
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                    </div> */}
                    <div className="waves-block">
                    <video style={{'width':'100%', 'height':'100%'}} controls>
                        <source src="http://hydracarcare.com/hydra.mp4" type="video/mp4"></source>
                    </video>
                    </div>
                    {/* <a href="#" className="video" data-lity=""><i className="fa fa-play"></i></a> */}
                {/* </div> */}
                </div>
                </div>
    
                <Footer/>
          <div id="myModal" className="modal" style={{display: this.state.show === true ? 'block' : 'none' }}>
          <div className="modal-content" style={{width: '40% !important'}}>
            <form>
            <div className="row">
            <div className="mb-3 mt-3">
                <input type="text" className="form-control" maxLength={10} id="mobilenumber" placeholder="Enter Mobile Number" name="mobilenumber" />
            </div>
             </div>
             <div className="row" style={{padding: '10px'}}>
                {
                    this.state.carBrandList.map( (carBrand,index) => {
                        return (
                            <>
                                <div key={index} className="col-3" style={{boxShadow: '-3px -1px 11px 0px'}}><img style={{padding: '10px'}} src={APIURL+'getbrandimage/'+carBrand.id} /></div>
                            </>
                        );
                    })
                }
                
             </div>
              <div className="col-12" style = {{display: 'inline-flex'}}>
              {
                this.state.mobileotp === true ? <div className="col-6">
                <button type="button"  className="btn btn-primary">Submit</button>
              </div> : <div className="col-6">
                <button type="button"  className="btn btn-primary">Submit</button>
              </div>
              }
              <div className="col-6" style={{textAlign: 'right'}}>
                <button type="button" className="btn btn-danger">close</button>
              </div>
              </div>
            </form>
          </div>
        </div>
        </>
        );
    }
  };
  
  export default Home;