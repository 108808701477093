import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../assets/css/main.css?v=1.0.0';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/animate.min.css?v=1.0.0';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../assets/css/font-awesome.min.css';
import '../assets/css/remixicon.min.css';
import AxiosCall from "../utils/axiosCall";
import { APIURL } from '../utils/constants';
import Footer from "./footerpage";
import HomeHeader from "./homeheader";
import { Link } from "react-router-dom";
import "../assets/css/modelpopup.css?v=0.0.1";
import AddressBar from "./addressbar";

class Carbrands extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            show:false,
            carBrandList:[],
        }
        this.showvehicleClick = this.showvehicleClick.bind(this);
        this.loadcarBrandList = this.loadcarBrandList.bind(this);
    }
    showvehicleClick(){
        this.setState({show:true});
    }

    componentDidMount() {
        this.loadcarBrandList();
     }

     async loadcarBrandList(){
        let carBrandListArray = await AxiosCall.getLoadCarBrandList();
        var brandList = carBrandListArray.data.brand;
        this.setState({carBrandList:brandList});
     }

    render() {
        return (
            <> 
                <HomeHeader/>
                <AddressBar/>
                
                <div className="heroslider">
                <div className="container">
                <div className="row">
                
                </div>
                <div className="row" style={{padding: '10px'}}>
                    {
                        this.state.carBrandList.map( (carBrand,index) => {
                            return (
                                <>
                                    <div className="col-3" style={{boxShadow: '-3px -1px 11px 0px'}}>
                                        <Link to={{ pathname:'/carbrand/'+carBrand.id , state : { id: carBrand.id}} }><img style={{padding: '10px', width:"150px;"}} src={ APIURL+'getbrandimage/'+carBrand.id } /></Link>
                                        {/* <a href={'carbrand/'+carBrand.id}></a> */}
                                        </div>
                                </>
                            );
                        })
                    }
                    
                </div>
                </div>
                </div>
                <Footer/>
        </>
        );
    }
  };
  
  export default Carbrands;