import React, { useEffect, useRef, useState } from "react";
import { APIURL } from '../utils/constants';
import axios from "axios";

const AddressBar = () => {

    const inputRef = useRef();
    const autoCompleteRef = useRef();
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [location, setLocation] = useState("");
    
    const options = {
        componentRestrictions: { country: "in" },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["establishment"]
       };
       useEffect(() => {

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                console.log(position);
                setPosition({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
              localStorage.setItem('addresslatitude',position.coords.latitude);
              localStorage.setItem('addresslongitude',position.coords.longitude);
            var GEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCqd-lFd3L4bPo0uwL39JqVkGKjLSwisBc&latlng=' + position.coords.latitude + '%2C' + position.coords.longitude + '&language=en';
            axios.get(GEOCODING).then( (res) => {
                setLocation(res.data.results[1].formatted_address);
                localStorage.setItem('addressname',res.data.results[1].formatted_address);
            });
            });
          } else {
            console.log("Geolocation is not available in your browser.");
          }

        // autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        //  inputRef.current,
        //  options
        // );
        // autoCompleteRef.current.addListener("place_changed", async function () {
        //     const place = await autoCompleteRef.current.getPlace();
        //     console.log(place)
        //     setPosition({
        //         latitude: place.geometry.location.lat(),
        //         longitude: place.geometry.location.lng(),
        //       });
        //       localStorage.setItem('addresslatitude',place.geometry.location.lat());
        //       localStorage.setItem('addresslongitude',place.geometry.location.lng());
        //       var GEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCqd-lFd3L4bPo0uwL39JqVkGKjLSwisBc&latlng=' + place.geometry.location.lat() + '%2C' + place.geometry.location.lng() + '&language=en';
        //       axios.get(GEOCODING).then( (res) => {
        //           setLocation(res.data.results[1].formatted_address);
        //           localStorage.setItem('addressname',res.data.results[1].formatted_address);
        //       });
        //    });

       }, []);

       const handleChange = () => {
       }

        return(
            <>
            <header className="header">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                    <div className="locationbox">
                        <div className="mapicon"><i className="ri-map-pin-line"></i></div>
                        <div className="addressinfo" onClick={handleChange}>{location}</div>
                        {/* <input ref={inputRef} /> */}
                        <div className="addressarrow"><i className="ri-arrow-down-s-line"></i></div>
                    </div>
                    </div>
                    <div className="col-md-8">
                    <div className="addvcoin">
                        {
                            (localStorage.getItem('selectedcarBrandModelId') != null) ? 
                            <a href="/carbrands" className="addedvechicle"> 
                            <img src={APIURL+'getbrandimage/'+ localStorage.getItem('selectedcarBrandId')}  className="cariconadded"></img>
                            <p style={{fontSize:'14px',margin: '0'}}>{localStorage.getItem('selectedcarBrandModelName')}</p> <i className="ri-add-line"></i> </a> 
                            :
                            <a href="/carbrands" className="addvechicle"> 
                            <img src="../assets/images/caricon.png" alt="" className="caricon" /> Add New Vehicle  <i className="ri-add-line"></i></a>
                        }
                    </div>
                    </div>
                </div>
                </div>
                </header>
            </>
        );
}

export default AddressBar;