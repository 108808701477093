import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../assets/css/main.css?v=1.0.0';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/animate.min.css?v=1.0.0';
import '../assets/css/font-awesome.min.css';
import '../assets/css/remixicon.min.css';
import AxiosCall from "../utils/axiosCall";
import Footer from "./footerpage";
import "../assets/css/main.css?v=0.0.2";
import "../assets/css/modelpopup.css?v=0.0.1";
import HomeHeader from "./homeheader";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { event } from "jquery";
import { Link } from "react-router-dom";
import { Interweave } from "interweave";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Bookingslist extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            show:false,
            mobileotp:false,
            mobileotpnumber:'',
            serviceslist : [],
            localstoreserviceids:[],
            category:this.props.params.id,
            mobilenumber:'',
            carCategories:[],
            selectedservicecartid:'',
            paramid:props.params.id ?? ''
        };
        this.loadServicesList = this.loadServicesList.bind(this);
        this.showContentDetails = this.showContentDetails.bind(this);
        this.closepopmenu = this.closepopmenu.bind(this);
        this.submitChecktoCart = this.submitChecktoCart.bind(this);
        this.selectedMobileNumber = this.selectedMobileNumber.bind(this);
        this.validateOTPWithMobileNumber = this.validateOTPWithMobileNumber.bind(this);
        this.selectotpMobileCheck = this.selectotpMobileCheck.bind(this);
        this.loadcarCategoriesList = this.loadcarCategoriesList.bind(this);
      }
  
      componentDidMount(){ 
        this.loadServicesList();
        this.loadcarCategoriesList();
      }
      
      async loadServicesList()  {
        const serviceslistData = await AxiosCall.getUserBookinglist(this.state.category);
        // console.log(serviceslistData.data.data)
        this.setState({serviceslist:serviceslistData.data})
      }

      async loadcarCategoriesList(){
        let carCategoriesListArray = await AxiosCall.getLoadCarCategoriesDetailsList();
        this.setState({carCategories:carCategoriesListArray.data.data});
     }

      selectedMobileNumber(event){
        this.setState({mobilenumber:event.target.value});
      }

      closepopmenu(){
        this.setState({show:false})
      }

      showContentDetails(service,event){
        if(localStorage.getItem('mobilenumber') !== null){
          var localstoreserviceids = [];
          if(localStorage.getItem('localstoreserviceids') !== null){
            localstoreserviceids = JSON.parse(localStorage.getItem('localstoreserviceids'));
          }
          localstoreserviceids = [... new Set(localstoreserviceids)];
          localStorage.setItem('localstoreserviceids',JSON.stringify([...localstoreserviceids, service]));
          window.location = "/calender";
        } else {
          this.setState({selectedservicecartid:service});
          this.setState({ show:true})
        }
      }

      selectotpMobileCheck(event){
        this.setState({mobileotpnumber:event.target.value});
      }

      submitChecktoCart(){
        if(this.state.mobilenumber.length > 9 && this.state.mobilenumber.match(/^[0-9]{10}$/)){
          this.setState({mobileotp:true});
        } else {
          withReactContent(Swal).fire({html: <i>Please Enter valid mobile Number</i>});
        }
        return false;
      }

      validateOTPWithMobileNumber(){
        if(this.state.mobilenumber.length > 9 && this.state.mobilenumber.match(/^[0-9]{10}$/)){
          if(this.state.mobileotpnumber.length > 4){
            localStorage.setItem('mobilenumber',this.state.mobilenumber);
            var localstoreserviceids = [];
            if(localStorage.getItem('localstoreserviceids') !== null){
              localstoreserviceids = JSON.parse(localStorage.getItem('localstoreserviceids'));
            }
            localStorage.setItem('localstoreserviceids',JSON.stringify([...localstoreserviceids, this.state.selectedservicecartid]));
            window.location = "/calender";
          }
        } else {
          withReactContent(Swal).fire({html: <i>Please Enter valid mobile Number</i>});
        }
      }

    render() {
        return (
            <> 
        <HomeHeader/>
        <div className="slotbooking">
  <div className="container">
    <ul className="slotsteps">
      <li className={this.state.paramid == 'slotbooking' ? 'active' : '' }>
        <a href="/bookings/slotbooking"><span className="slotimg"> <img src="images/step1.png" alt="" /> </span>
        <h4> <span className="num">1</span> SLOT <br /> BOOKING</h4>
        </a>
      </li>
      <li className={this.state.paramid == 'bookingaccepted' ? 'active' : '' }>
      <a href="/bookings/bookingaccepted"><span className="slotimg"> <img src="images/step2.png" alt="" /> </span>
        <h4> <span className="num">2</span> BOOKING <br /> ACCEPTED</h4></a>
      </li>
      <li className={this.state.paramid == 'ontheway' ? 'active' : '' }>
      <a href="/bookings/ontheway"><span className="slotimg"> <img src="images/step3.png" alt="" /> </span>
        <h4> <span className="num">3</span> ON THE WAY <br /> TO LOCATION</h4></a>
      </li>
      <li className={this.state.paramid == 'servicestarted' ? 'active' : '' }>
      <a href="/bookings/servicestarted"><span className="slotimg"> <img src="images/step4.png" alt="" /> </span>
        <h4> <span className="num">4</span> SERVICE <br /> STARTED</h4></a>
      </li>
      <li className={this.state.paramid == 'servicecompleted' ? 'active' : '' }>
      <a href="/bookings/servicecompleted"><span className="slotimg"> <img src="images/step5.png" alt="" /> </span>
        <h4> <span className="num">5</span> SERVICE <br /> COMPLETED</h4></a>
      </li>
    </ul>
  </div>
</div>
        <div className="serviceslist">
        <div className="tab-content">
          <div className="tab-pane active" role="tabpanel" id="washing">
          {
              this.state.serviceslist.map( (service,index) => {
                return (
                  service.data.map( (servicer,kindex) => {
                    return (
                            <div className="carlist" key={kindex}>
                            <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                <h3 className="titletwo">{servicer.serviceTitle}</h3>
                                <Interweave content={servicer.elements} />
                                
                                </div>
                                <div className="col-md-5">
                                <img src="../assets/images/carwrap.png" alt="" className="carimg" />
                                </div>
                            </div>
                            </div>
                        </div>
                    )
                })
                )
            })
          }
          </div>
        </div>
      </div>
        <Footer/>
        <div id="myModal" className="modal" style={{display: this.state.show === true ? 'block' : 'none' }}>
          <div className="modal-content">
            <form>
              <div style={{padding:'40px'}}>
              <div className="mb-3 mt-3">
                <label htmlFor="email">Mobile Number:</label>
                <input type="text" className="form-control" maxLength={10} onChange={this.selectedMobileNumber} id="mobilenumber" placeholder="Enter Mobile Number" name="mobilenumber" />
              </div>
              {
              (this.state.mobileotp === true) ? <div className="mb-3">
              <label htmlFor="pwd">OTP</label>
                <input type="text" className="form-control" id="otpmobile" onChange={this.selectotpMobileCheck} placeholder="Enter OTP" name="otpmobile" />
              </div> : <div className="mb-3"></div>
              }
              </div>
              <div className="col-12" style = {{display: 'inline-flex'}}>
              {
                this.state.mobileotp === true ? <div className="col-6">
                <button type="button" onClick={ this.validateOTPWithMobileNumber } className="btn btn-primary">Submit</button>
              </div> : <div className="col-6">
                <button type="button" onClick={ this.submitChecktoCart } className="btn btn-primary">Submit</button>
              </div>
              }
              <div className="col-6" style={{textAlign: 'right'}}>
                <button type="button" onClick={ this.closepopmenu } className="btn btn-danger">close</button>
              </div>
              </div>
            </form>
          </div>
        </div>
        </>
        );
    }
  };
  
  export default withParams(Bookingslist);