import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../assets/css/main.css?v=1.0.0';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/animate.min.css?v=1.0.0';
import '../assets/css/font-awesome.min.css';
import '../assets/css/remixicon.min.css';
import AxiosCall from "../utils/axiosCall";
import Footer from "./footerpage";
import "../assets/css/main.css?v=0.0.2";
import "../assets/css/modelpopup.css?v=0.0.1";
import HomeHeader from "./homeheader";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker from "react-datepicker";
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { APIURL } from '../utils/constants';
import { event } from "jquery";
import { parse } from "date-fns";
import AddressBar from "./addressbar";
import { Interweave } from "interweave";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Cart extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            timeslotList : [],
            selecteddate:new Date(),
            selectedtimeslotid:[],
            serviceslistByCart:[],
            totalprice:0
        };
        this.loadTimeslotList = this.loadTimeslotList.bind(this);
        this.submitChecktoCart = this.submitChecktoCart.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
        this.redirectBack = this.redirectBack.bind(this);
      }
  
      componentDidMount(){ this.loadTimeslotList(); }

      redirectBack(){
        window.location = "/";
      }
      
      async loadTimeslotList()  {
        var localstoreserviceids = [];
        if(localStorage.getItem('localstoreserviceids') !== null){
            localstoreserviceids = JSON.parse(localStorage.getItem('localstoreserviceids'));
        }
        localstoreserviceids = [... new Set(localstoreserviceids)];
        localstoreserviceids = localstoreserviceids.filter(item => item);
        const serviceslistById = await AxiosCall.getServiceslistByIds(localstoreserviceids);
        var totalPriceState = 0;
        for(var i=0;i<serviceslistById.data.length;i++){
          totalPriceState += (serviceslistById.data[i].priceList[0].price) * 1;
        }
        this.setState({totalprice:totalPriceState});
        this.setState({serviceslistByCart:serviceslistById.data});
      }

      async submitChecktoCart(){
        const serviceslistById = await AxiosCall.cartfinishing(this.state.totalprice);
        if(serviceslistById.data === true){
          localStorage.removeItem('localstoreserviceids');
          localStorage.removeItem("selectedtimeslotid");
          localStorage.removeItem("selecteddate");
          window.location = "/";
        }
        return false;
      }
      
      removeFromCart(serviceid, event){
        var localstoreserviceids = [];
        if(localStorage.getItem('localstoreserviceids') !== null){
            localstoreserviceids = JSON.parse(localStorage.getItem('localstoreserviceids'));
        }
        const serviceindex = localstoreserviceids.indexOf(serviceid);
        if (serviceindex > -1) { // only splice array when item is found
          localstoreserviceids.splice(serviceindex, 1); // 2nd parameter means remove one item only
        }
        localStorage.setItem('localstoreserviceids',JSON.stringify(localstoreserviceids));
        window.location = "/cart";
      }
    render() {
        return (
            <> 
        <HomeHeader/>
        {/* <AddressBar/> */}
        <div class="carthed">
  <div class="container">
    <div class="cartrow">
    
<h4>Order Summary </h4>
</div>
  </div>
 </div> 
<div class="cartitems">
  <div class="container">
    {
      this.state.serviceslistByCart.map ( (service,index) => {
        return (
          <>
          <div class="cartbox" key={index}>
          <div class="row"> <div class="col-md-5">
          <img src={APIURL+"servieimages/"+service.id} alt="" class="carimg" />
          </div>
          <div class="col-md-7">
          <h3 class="titletwo">{service.serviceTitle}</h3>
          <Interweave content={service.elements} />
          <div class="btns">
          <span class="price"> <i class="fa fa-inr"></i> {service.priceList[0].price}/-</span>
          <a href="#" onClick={ (event) => { this.removeFromCart(service.id+'-'+service.priceList[0].modelid, event)}} className="cta">Remove</a>
          </div>
          </div>
          </div>
          </div>
          </>
        )
      })
    }
  <div class="cartbtm">
  <h5>Total Bill</h5>
  <div class="totalist">
  {
      this.state.serviceslistByCart.map ( (service,index) => {
        return (
          <>
           <div class="totalrow"><h6>{service.serviceTitle}</h6>       
              <span class=""> <i class="fa fa-inr"></i>  {service.priceList[0].price}/-</span>        
          </div>
    </>
        );
      })
    }
   
  </div>
  {/* 
    <div class="totalist gst">
      <div class="totalrow">      <h6>Gst</h6>       
          <span class=""> <i class="fa fa-inr"></i> 90/-</span>        
      </div>
      <div class="totalrow">      <h6>Service Fee</h6>       
        <span class=""> <i class="fa fa-inr"></i> 50/-</span>        
    </div> 
    </div> */}
  {/* 
    <div class="totalist">
      <div class="totalrow subtotal">      <h6>Subtotal</h6>       
          <span class=""> <i class="fa fa-inr"></i> 1138/-</span>        
      </div>
      <div class="totalrow discount">      <h6>Discounts</h6>       
        <span class=""> <i class="fa fa-inr"></i> 114/-</span>        
    </div> 
    </div>
  */}

  <div class="totalist">
    <div class="totalrow subtotal">      <h6>Total</h6>       
        <span class=""> <i class="fa fa-inr"></i> {this.state.totalprice}/- </span>        
    </div>
   
  </div>

</div>
<div class="paybar">
  <div className="abc col-12" style={{display: 'inline-flex'}}>
    <div className="col-6" style={{textAlign:'left'}}><button onClick={ this.redirectBack } className="btn btn-danger">Back</button></div>
    <div className="col-6" style={{textAlign:'right'}}><button onClick={ this.submitChecktoCart } className="btn btn-success">Finish</button></div>
  </div>
  
</div>
  </div>
  </div>
        <Footer/>
        </>
        );
    }
  };
  
  export default withParams(Cart);